import { decode } from "blurhash";

const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const element = entry.target;
      const isIntersecting = entry.isIntersecting;
      if (isIntersecting) {
        element.previousElementSibling.classList.add('fade');
        return; // if we added the class, exit the function
      }
    });
});

const updateCardsBlurHash = function() {
    const hashes = document.querySelectorAll('[data-blur-hash]')
    hashes.forEach(img => {
        if(img.dataset.blurHash == '') {
            //img.classList.remove('opacity-0');
        } else {
            img.classList.remove('opacity-0');
            const pixels = decode(img.dataset.blurHash, 32, 32);
            const canvas = document.createElement("canvas");
            canvas.width = 32;
            canvas.height = 32;
            canvas.style.width = '100%';
            canvas.style.height = '100%';
            canvas.style.position = 'absolute';
            canvas.style.top = '0';
            canvas.style.bottom = '0';
            canvas.style.left = '0';
            canvas.style.right = '0';
            const ctx = canvas.getContext("2d");
            const imageData = ctx.createImageData(32, 32);
            imageData.data.set(pixels);
            ctx.putImageData(imageData, 0, 0);
            img.parentElement.prepend(canvas);
        }

        // create scroll listeners to add the fade class when scrolled into view
        observer.observe(img);

    });
}

Livewire.on('searchUpdate', function () {
    updateCardsBlurHash();
});

Livewire.on('pageChanged', function () {
    updateCardsBlurHash();
});

updateCardsBlurHash();
