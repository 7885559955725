
document.addEventListener('DOMContentLoaded', function() {
    const modals = document.querySelectorAll('.js-contribute');

    modals.forEach(item => {
        item.addEventListener('click', function(event) {
            event.preventDefault();
            document.body.classList.add('contribute-modal-active');
        })
    });

    var openmodal = document.querySelectorAll('.contribute-modal-open')
    for (var i = 0; i < openmodal.length; i++) {
      openmodal[i].addEventListener('click', function(event){
        event.preventDefault()
        toggleModal()
      })
    }

    const overlay = document.querySelector('.contribute-modal .modal-overlay')
    if(overlay) {
        overlay.addEventListener('click', toggleModal)

        var closemodal = document.querySelectorAll('.contribute-modal .modal-close')
        for (var i = 0; i < closemodal.length; i++) {
          closemodal[i].addEventListener('click', toggleModal)
        }

        document.onkeydown = function(evt) {
          evt = evt || window.event
          var isEscape = false
          if ("key" in evt) {
              isEscape = (evt.key === "Escape" || evt.key === "Esc")
          } else {
              isEscape = (evt.keyCode === 27)
          }
          if (isEscape && document.body.classList.contains('contribute-modal-active')) {
              toggleModal()
          }
        };


        function toggleModal () {
          console.log('toggling modal');
          const body = document.querySelector('body')
          body.classList.toggle('contribute-modal-active')
        }
    }
});