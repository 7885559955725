
document.addEventListener('DOMContentLoaded', function() {
  const navButton = document.querySelector('#js-nav-account-button');

  if(navButton) {
    navButton.addEventListener('click', function(event) {
      event.preventDefault();
      const dropDown = document.querySelector('.nav-account-holder');

      if (dropDown.classList.contains('nav-account-holder--open')) {
        dropDown.classList.remove('nav-account-holder--open');
      } else {
        dropDown.classList.add('nav-account-holder--open');
      }
    });
  }

  const hamburgerButton = document.querySelector('#js-nav-hamburger');
  if(hamburgerButton) {
    hamburgerButton.addEventListener('click', function(event) {
      event.preventDefault();
      if(document.body.classList.contains('nav-open')) {
        document.body.classList.remove('nav-open');
      } else {
        document.body.classList.add('nav-open');
      }
    });
  }

});
