document.addEventListener('DOMContentLoaded', function(event) {

    // Fade in clouds after DOM loads
    const clouds = document.querySelector('.clouds');
    if(clouds) {
        clouds.classList.add('clouds--loaded');
        setTimeout(() => {
            const liberty = document.getElementById('lady_liberty');
            if(liberty)
                liberty.classList.add('faded--active');
        },1000);

    }
});