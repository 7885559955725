document.addEventListener('DOMContentLoaded', function() {
    const faqElements = document.querySelectorAll('.js-faq-question');

    faqElements.forEach(item => {
        item.addEventListener('click', function(event) {
            event.preventDefault();
            console.log(this.parentNode);
            const answer = this.parentNode;
            if(answer.classList.contains('faq__qa--open')) {
                answer.classList.remove('faq__qa--open');
            } else {
                answer.classList.add('faq__qa--open');
            }
        })
    });
});