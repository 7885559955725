const msgerForm = get(".msger-inputarea");
const msgerInput = get(".msger-input");
const msgerChat = get(".msger-chat");
const BOT_IMG = "logo.svg";
const PERSON_IMG = "user.png";
const BOT_NAME = "UnPac";
const PERSON_NAME = "You";
const popupChat = document.getElementById('help-widget-popup');
const helpWidget = document.getElementById('help-widget');
const helpWidgetClose = document.getElementById('help-widget-close');

msgerForm.addEventListener("submit", event => {
  event.preventDefault();
  const msgText = msgerInput.value;
  if (!msgText) return;
  msgerInput.value = "";
  addChat(PERSON_NAME, "right", msgText);
  output(msgText);
});

function output(input) {
  let text = input.toLowerCase().replace(/[^\w\s]/gi, "").replace(/[\d]/gi, "").trim();

  setTimeout(() => {
    const msgHTML = `
      <div class="msg left-msg msg-thinking">
        <div class="msg-bubble">

          <div class="typing">
            <div class="typing__dot"></div>
            <div class="typing__dot"></div>
            <div class="typing__dot"></div>
          </div>

        </div>
      </div>
    `;
    msgerChat.insertAdjacentHTML("beforeend", msgHTML);
    msgerChat.scrollTop += 500;

    setTimeout(() => {
      window.axios.get('/chat', {
        "params": { "q" : text }
        })
        .then((data) => {
          const thinkingBubbles = document.querySelectorAll('.msg-thinking');
          thinkingBubbles.forEach((bubble) => {
            bubble.remove();
          })
          addChat(BOT_NAME, "left", data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    },5000);
  },1500);
};

function addChat(name, side, text) {
  const msgHTML = `
    <div class="msg ${side}-msg">
      <div class="msg-bubble">
        <div class="msg-info">
          <div class="msg-info-name">${name}</div>
          <div class="msg-info-time">${formatDate(new Date())}</div>
        </div>
        <div class="msg-text">${text}</div>
      </div>
    </div>
  `;
  msgerChat.insertAdjacentHTML("beforeend", msgHTML);
  msgerChat.scrollTop += 500;
}
function get(selector, root = document) {
  return root.querySelector(selector);
}
function formatDate(date) {
  const h = "0" + date.getHours();
  const m = "0" + date.getMinutes();
  return `${h.slice(-2)}:${m.slice(-2)}`;
}

helpWidget.addEventListener('click', triggerShowPopup);
helpWidgetClose.addEventListener('click', triggerClosePopup);

function triggerClosePopup(e) {
  e.stopPropagation();
  popupChat.classList.remove('msger--active');
}

function triggerShowPopup(e) {
  e.stopPropagation();

  if(popupChat.classList.contains('msger--active')) {
    popupChat.classList.remove('msger--active');
  } else {
    popupChat.classList.add('msger--active');
  };
}