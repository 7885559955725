const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const element = entry.target;
      const isIntersecting = entry.isIntersecting;

      if (isIntersecting) {
        element.classList.add('slideIn--active');
        return; // if we added the class, exit the function
      }
      // We're not intersecting, so remove the class!
      //element.classList.remove('slideIn--active');
    });
});


const images = document.querySelectorAll('.slideIn');
images.forEach(image => {
  observer.observe(image);
});

