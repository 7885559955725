document.addEventListener('DOMContentLoaded', function() {
    const inputs = document.querySelectorAll('input[name="is_working"]');

    if(inputs) {
        console.log(inputs);
        inputs.forEach(input => {
            input.addEventListener('click', function(event) {
                const value = document.querySelector('input[name="is_working"]:checked').value;
                console.log(value);
                const moreDiv = document.getElementById('more');
                if(value==1) {
                    moreDiv.classList.remove('hidden');
                } else {
                    moreDiv.classList.add('hidden');
                }
            })
        });
    }

});