document.addEventListener('DOMContentLoaded', function() {
    const elements = document.querySelectorAll('#show-more-trending');

    elements.forEach(item => {
        item.addEventListener('click', function(event) {
            event.preventDefault();
            event.target.classList.add('hidden');
            const cards = document.querySelectorAll('.trending-bill-holder');
            cards.forEach(card => {
                card.classList.remove('hidden');
            });
        })
    });
});