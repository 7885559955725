import ClipboardJS from 'clipboard';

const clipboard = new ClipboardJS('.clipboard-copy');

clipboard.on('success', function(e) {
    const tooltip = document.getElementById('copy-tooltip');
    tooltip.classList.remove('hidden');
    setTimeout(() => {
        tooltip.classList.add('hidden');
    },1000);
});
