import toggleClass from "./toggleClass";

var popupSize = {
    width: 780,
    height: 550
};

const shareButton = document.querySelector('.js-share');
if(shareButton) {
    shareButton.addEventListener('click', function(event) {
        event.preventDefault();
        const modal = document.getElementById('js-share-modal');
        toggleClass(modal, 'hidden');
    })
}
