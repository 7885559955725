import docCookies from '../utils/cookies';

document.addEventListener('DOMContentLoaded', function() {
  var openmodal = document.querySelectorAll('.modal-open')
  for (var i = 0; i < openmodal.length; i++) {
    openmodal[i].addEventListener('click', function(event){
      event.preventDefault()
      toggleModal()
    })
  }

  const overlay = document.querySelector('.modal .modal-overlay')
  if(overlay) {
      overlay.addEventListener('click', toggleModal)
      const body = document.querySelector('body')

      var closemodal = document.querySelectorAll('.modal .modal-close')
      for (var i = 0; i < closemodal.length; i++) {
        closemodal[i].addEventListener('click', toggleModal)
      }

      document.onkeydown = function(evt) {
        evt = evt || window.event
        var isEscape = false
        if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc")
        } else {
            isEscape = (evt.keyCode === 27)
        }
        if (isEscape && document.body.classList.contains('modal-active')) {
            toggleModal()
        }
      };

      function toggleModal () {
        body.classList.toggle('modal-active')

        docCookies.setItem('welcome_modal', true, Infinity, '/');
      }

      // check to see if user has never visited unpac and present modal
      const welcomeModal = docCookies.getItem('welcome_modal');
      if(!welcomeModal) {
        body.classList.add('modal-active');
      }

  }
});